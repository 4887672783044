const Preloader = () => {
    return (
        <>
            {/* <div className="arlo_tm_preloader">
        <div className="spinner_wrap">
          <div className="spinner"></div>
        </div>
      </div> */}
        </>
    )
}
export default Preloader;