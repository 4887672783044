
import Portfolio from '@/screens/portfolio';
import '@/styles/style.scss';
import '@/styles/plugins.scss'
function App() {
  return (
    <Portfolio />
  )

}

export default App
